import type { TThemePalette } from './palette.type';

export const DARK_PALETTE: TThemePalette = {
  mode: 'dark',
  common: { black: '#000000', white: '#FFFFFF' },
  primary: {
    main: '#3d77ad',
    light: '#6392BD',
    dark: '#2A5379',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#BDBDBD',
    light: '#CACACA',
    dark: '#848484',
    contrastText: '#000000',
  },
  error: {
    main: '#FF2424',
    light: '#FFDAD6',
    dark: '#5B0003',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#C7A600',
    light: '#E2C800',
    dark: '#8F7300',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#0288D1',
    light: '#90DCFE',
    dark: '#014175',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#1FAD2A',
    light: '#C4FBC8',
    dark: '#07690F',
    contrastText: '#FFFFFF',
  },
  grey: {
    '50': '#FAFAFA',
    '100': '#F5F5F5',
    '200': '#E7E7E7',
    '300': '#D7D7D7',
    '400': '#BDBDBD',
    '500': '#9E9E9E',
    '600': '#757575',
    '700': '#616161',
    '800': '#424242',
    '900': '#212121',
    A100: '#F5F5F5',
    A200: '#E7E7E7',
    A400: '#BDBDBD',
    A700: '#616161',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: '#fafafa',
    secondary: '#eeeeee',
    disabled: 'rgba(255, 255, 255, 0.5)',
  },
  divider: '#A9A9A9',
  background: {
    paper: '#1A1A1A',
    default: '#0b0b0b',
  },
  action: {
    active: '#FFFFFF',
    hover: 'rgba(255, 255, 255, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(255, 255, 255, 0.16)',
    selectedOpacity: 0.16,
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: '#A9A9A9',
    disabledOpacity: 0.38,
    focus: '#A9A9A9',
    focusOpacity: 0.12,
    activatedOpacity: 0.24,
  },
};
