'use client';

import { useTheme } from '@mui/material';

export const LogotypeSquare = ({
  color = 'primary',
}: {
  color?: 'primary' | 'white' | undefined;
}) => {
  const {
    palette: { primary, common },
  } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="svg18" viewBox="0 0 300 300">
      <defs id="defs1">
        <style id="style1">{`.cls-3{stroke-width: 0;fill: ${color === 'white' ? primary.main : common.white}}`}</style>
      </defs>
      <g id="Warstwa_3" data-name="Warstwa 3" transform="translate(-449.14 -83.73)">
        <path
          id="rect4"
          fill={color === 'white' ? common.white : primary.main}
          strokeWidth={0}
          d="M-14.81-11.85h1227.9v491.16H-14.81z"
        />
      </g>
      <g id="Warstwa_1" data-name="Warstwa 1">
        <g id="g8" transform="translate(-90.44 -84.04)">
          <g id="g7">
            <path
              id="path5"
              d="M241.34 356.18c-6.38 0-11.55-5.17-11.55-11.55V212.42c0-6.38 5.17-11.55 11.55-11.55 6.38 0 11.55 5.17 11.55 11.55v132.21c0 6.38-5.17 11.55-11.55 11.55z"
              className="cls-3"
            />
            <path
              id="path6"
              d="M282.67 341.81h-36.24c-6.38 0-11.55-5.17-11.55-11.55 0-6.38 5.17-11.55 11.55-11.55h36.24c6.38 0 11.55 5.17 11.55 11.55 0 6.38-5.17 11.55-11.55 11.55z"
              className="cls-3"
            />
            <path
              id="path7"
              d="M282.67 311.6h-36.24c-6.38 0-11.55-5.17-11.55-11.55 0-6.38 5.17-11.55 11.55-11.55h36.24c6.38 0 11.55 5.17 11.55 11.55 0 6.38-5.17 11.55-11.55 11.55z"
              className="cls-3"
            />
          </g>
          <path
            id="path8"
            d="M302.83 155.5c-.22-18.38-15.37-33.3-33.88-33.3-4.86 0-9.53.99-13.9 2.94-7.09-8.66-17.45-13.24-30-13.24-23.4 0-42.73 18.15-44.12 41.36-12.55 6.27-20.64 19.31-20.64 33.29 0 20.54 16.79 37.25 37.43 37.25h85.44c20.64 0 37.43-16.71 37.43-37.25 0-13.25-6.64-24.84-17.76-31.05zm-98.48 10.19c-.55-2.2-4.87-21.68 12.52-29.02 15.58-6.57 26.79 4.63 30.89 9.75.76.95 2.7 1.06 3.44.59 9.64-6.16 18.07-6.53 25.07-1.09 6.53 5.08 8.51 11.64 6.04 20.07-.21.72.13 2.43 1.04 2.79 12.69 5.02 14.06 14.1 14.06 17.77 0 7.89-6.39 14.31-14.25 14.31h-85.44c-4.01 0-7.89-1.67-10.65-4.59-2.56-2.7-3.83-6.14-3.6-9.69.91-13.89 10.94-16.05 16.93-17.34.79-.17 1.51-.33 2.13-.49 1.25-.33 2.14-1.82 1.82-3.07z"
            className="cls-3"
          />
        </g>
      </g>
    </svg>
  );
};
