'use client';

import { Box } from '@mui/material';

import { LogotypeSquare } from './LogoSquareSafeAndTrust';
import { LogoSafeAndTrust } from './LogoSafeAndTrust';

import type { ILogotypeProps } from './logotype.type';

export const Logotype = ({
  height,
  square = false,
  sx = {},
  color = 'primary',
}: ILogotypeProps) => (
  <Box
    height={height}
    sx={{
      ...sx,
      svg: {
        height: `${height}px`,
      },
    }}
  >
    {square ? <LogotypeSquare color={color} /> : <LogoSafeAndTrust color={color} />}
  </Box>
);
